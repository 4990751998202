export default [
  {
    header: 'menu.settings.title',
  },
  {
    title: 'menu.settings.general',
    route: 'settings-general',
    icon: 'SettingsIcon',
  },
  {
    title: 'menu.settings.company',
    route: 'settings-company',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'menu.settings.calendar',
    route: 'settings-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'menu.settings.sales',
    route: 'settings-sales',
    icon: 'ShoppingBagIcon',
  },
]
