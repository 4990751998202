export default [
  {
    header: 'menu.service.title',
  },
  {
    title: 'menu.service.messages',
    route: 'messages',
    icon: 'MailIcon',
  },
  {
    title: 'menu.service.feedback',
    route: 'feedback',
    icon: 'MessageCircleIcon',
  },
]
