export default [
  {
    header: 'menu.products.title',
  },
  {
    title: 'menu.products.products',
    route: 'products',
    icon: 'TagIcon',
  },
  {
    title: 'menu.products.services',
    route: 'services',
    icon: 'BoxIcon',
  },
]
