export default [
  {
    header: 'menu.shop.title',
  },
  {
    title: 'menu.shop.calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'menu.shop.customers',
    route: 'customers',
    icon: 'UsersIcon',
  },
  {
    title: 'menu.shop.register',
    route: 'register',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'menu.shop.reports',
    route: 'reports',
    icon: 'PieChartIcon',
  },
]
